<template>
  <div class="h-full w-full">
    <v-row v-if="loading" justify="center" align="center" class="h-full w-full">
      <v-col cols="auto">
        <h3>{{ preparingFormHeader }}</h3>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>

    <v-row v-if="!loading && !applied && !applyFormDisabled" justify="center" class="pt-5 mb-10">
      <v-col cols="11" md="6">
        <v-form v-model="valid">
          <v-row>
            <v-col cols="11" md="12">
              <h1>{{ applyFormHeader }}</h1>
            </v-col>
            <v-col
              v-if="jobData.showDescription"
              cols="11"
              md="12"
              class="text-pre-wrap"
              v-text="jobData.description"
            />
            <v-col cols="11" md="12">
              <h3 v-html="basicInformationHeader" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="applyData.name"
                :label="firstNameLabel"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="applyData.surname"
                :label="lastNameLabel"
                outlined
                dense
                maxlength="150"
                hide-details="auto"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="applyData.email"
                :label="emailLabel"
                outlined
                dense
                maxlength="254"
                hide-details="auto"
                :rules="[rules.emailValidation]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="applyData.phone"
                :label="phoneNumberLabel"
                outlined
                dense
                maxlength="31"
                hide-details="auto"
                :rules="jobData.phoneRequired ? [rules.maxPhoneLength, rules.required] : [rules.maxPhoneLength]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-if="jobData.preferredCityVisible"
                v-model="applyData.preferredCity"
                :label="preferredCityLabel"
                outlined
                dense
                maxlength="128"
                hide-details="auto"
                :rules="jobData.preferredCityRequired ? [rules.required] : []"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <file-pond
                v-model="applyData.files"
                :label-idle="attachmentLabel"
                allow-multiple="true"
                accepted-file-types="application/pdf, image/png, image/jpeg"
                max-file-size="20MB"
                max-total-file-size="20MB"
              />
            </v-col>
            <v-col v-for="(q, index) in jobData.questions" :key="index" cols="12">
              <h3>{{ q.question }}{{ q.required ? '*' : '' }}</h3>
              <v-textarea
                auto-grow
                outlined
                dense
                rows="5"
                maxlength="150"
                :rules="q.required ? [rules.required] : []"
                @input="applyAnswer($event, q)"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="applyData.companyGdprAccepted"
                :rules="[rules.required]"
                :disabled="!companyData.jobGdprConsent"
                class="text-justify"
              >
                <template #[`label`]>
                  <v-clamp :expanded="baseGdprExpanded" autoresize :max-lines="4" ellipsis="...* ">
                    {{ companyData.jobGdprConsent }}*
                    <template #[`after`]="{ clamped, expanded }">
                      <v-chip
                        v-if="clamped || expanded"
                        color="#133c55"
                        outlined
                        x-small
                        @click.stop.prevent="baseGdprExpanded = !baseGdprExpanded"
                      >
                        {{ baseGdprExpanded ? minimizeLabel : expandLabel }}
                      </v-chip>
                    </template>
                  </v-clamp></template
                >
              </v-checkbox>
            </v-col>
            <v-col v-if="jobData.showTalentPoolConsent" cols="12">
              <v-checkbox
                v-model="applyData.talentPoolGdprAccepted"
                :rules="companyData.talentPoolGdpr.required ? [rules.required] : []"
                class="text-justify"
              >
                <template #[`label`]>
                  <v-clamp
                    :expanded="talentPoolExpanded"
                    autoresize
                    :max-lines="4"
                    :ellipsis="companyData.talentPoolGdpr.required ? '...* ' : '... '"
                  >
                    {{ companyData.talentPoolGdpr.consent }}{{ companyData.talentPoolGdpr.required ? '*' : '' }}
                    <template #[`after`]="{ clamped, expanded }">
                      <v-chip
                        v-if="clamped || expanded"
                        color="#133c55"
                        outlined
                        x-small
                        @click.stop.prevent="talentPoolExpanded = !talentPoolExpanded"
                      >
                        {{ talentPoolExpanded ? minimizeLabel : expandLabel }}
                      </v-chip>
                    </template>
                  </v-clamp></template
                >
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="applyData.permitToContactViaEmail"
                :label="`${permitToContactViaEmailText}*`"
                :rules="[rules.required]"
                :disabled="!companyData.registrationName"
                class="text-justify"
              ></v-checkbox>
            </v-col>
            <v-col v-for="additionalConsent in jobData.additionalConsents" :key="additionalConsent.id" cols="12">
              <v-checkbox
                v-model="additionalConsent.isAccepted"
                :rules="additionalConsent.required ? [rules.required] : []"
              >
                <template #[`label`]>
                  <v-clamp
                    :expanded="additionalConsent.expanded"
                    autoresize
                    :max-lines="4"
                    :ellipsis="additionalConsent.required ? '...* ' : '... '"
                  >
                    {{ additionalConsent.consent }} {{ additionalConsent.required ? '*' : '' }}
                    <template #[`after`]="{ clamped, expanded }">
                      <v-chip
                        v-if="clamped || expanded"
                        color="#133c55"
                        outlined
                        x-small
                        @click.stop.prevent="additionalConsent.expanded = !additionalConsent.expanded"
                      >
                        {{ additionalConsent.expanded ? 'Minimize' : 'Expand' }}
                      </v-chip>
                    </template>
                  </v-clamp></template
                >
                ></v-checkbox
              >
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-btn
                v-if="!applied"
                x-large
                :disabled="!canApply"
                elevation="0"
                color="primary"
                :loading="applySubmitting"
                class="ma-3"
                @click="submitApplication"
                >{{ submitButton }}</v-btn
              >
            </v-col>
            <v-col cols="12" class="mt-5 text-justify" v-html="privacyPolicyInfo" />
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-if="!loading && applied && !applyFormDisabled" justify="center" align="center" class="h-full w-full">
      <v-col cols="auto" class="d-flex align-center justify-center flex-column text-center">
        <h1>{{ submittedHeader }}</h1>
        <h3>{{ submittedInfoLabel }}</h3>
        <v-btn class="mt-8" elevation="0" color="primary" @click="redirectToCandidatePanel">{{
          candidatePanelLabel
        }}</v-btn>
      </v-col>
    </v-row>

    <v-row v-if="applyFormDisabled" justify="center" align="center" class="h-full w-full">
      <v-col cols="auto">
        <h1>{{ offerNoLongerAvailableLabel }}</h1>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import VClamp from 'vue-clamp'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import { axiosIns } from '@/plugins/axios'
import router from '@/router'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileEncode, FilePondPluginFileValidateSize)

export default {
  name: 'ApplyForm',
  components: {
    VClamp,
    FilePond,
  },
  setup() {
    const { applyToJob } = useActions('jobs', ['applyToJob'])

    const loading = ref(false)
    const valid = ref(false)

    const applyData = ref({
      name: '',
      surname: '',
      email: '',
      phone: '',
      preferredCity: '',
      answers: [],
      files: [],
      companyGdprAccepted: false,
      talentPoolGdprAccepted: false,
      permitToContactViaEmail: false,
    })

    const baseGdprExpanded = ref(false)
    const talentPoolExpanded = ref(false)

    const applied = ref(false)
    const applySubmitting = ref(false)
    const applyFormDisabled = ref(false)

    const jobData = ref({})
    const companyData = ref({})

    const isEng = computed(() => jobData.value.applyFormLang === 'eng')
    const isRu = computed(() => jobData.value.applyFormLang === 'ru')
    const isEs = computed(() => jobData.value.applyFormLang === 'es')
    const areAttachmentsEmpty = computed(() => !applyData.value.files.length)
    const canApply = computed(() =>
      jobData.value.attachmentsRequired ? valid.value && !areAttachmentsEmpty.value : valid.value,
    )
    const rules = computed(() => {
      return {
        required: (value) => !!value || (isRu.value ? 'Обязательно' : 'Required'),
        maxPhoneLength: (value) =>
          (value || '').length <= 31 || (isRu.value ? 'Максимум 31 символ' : 'Max 31 characters'),
        emailValidation: (value) =>
          !!value.match(/^$|^[a-zA-Z0-9._%+-.]+@[a-zA-Z0-9]+\.[a-z]+/) ||
          (isRu.value ? 'Электронное письмо неправильно отформатировано' : 'Email is incorrectly formatted'),
      }
    })

    const submitApplication = async () => {
      if (!valid) return

      applySubmitting.value = true
      const files = applyData.value.files.map((file) => {
        return {
          file: file.getFileEncodeBase64String(),
          name: file.filename,
        }
      })
      const payload = {
        user: {
          firstName: applyData.value.name,
          lastName: applyData.value.surname,
          email: applyData.value.email,
          phone: applyData.value.phone,
        },
        ...(jobData.value.preferredCityVisible ? { preferredCity: applyData.value.preferredCity } : {}),
        offer: router.currentRoute.params.id,
        companyJobGdpr: {
          accepted: applyData.value.companyGdprAccepted,
          consent: companyData.value.jobGdprConsent,
          wasRequired: true,
        },
        companyTalentPoolGdpr: {
          accepted: applyData.value.talentPoolGdprAccepted,
          consent: companyData.value.talentPoolGdpr.consent,
          wasRequired: companyData.value.talentPoolGdpr.required,
        },
        contactViaEmail: {
          accepted: applyData.value.permitToContactViaEmail,
          consent: permitToContactViaEmailText.value,
          wasRequired: true,
        },
        answers: [...applyData.value.answers],
        applyFormSource: router.currentRoute.query.source,
        files,

        // TODO: use reduce here or something like that
        additionalConsents: jobData.value.additionalConsents.map((additionalConsent) => ({
          consent: additionalConsent.consent,
          accepted: additionalConsent.isAccepted,
        })),
      }
      try {
        await applyToJob({
          jobId: router.currentRoute.params.id,
          payload,
        })
        applied.value = true
        applySubmitting.value = false
        applyData.value = {
          name: '',
          surname: '',
          email: '',
          phone: '',
          preferredCity: '',
          answers: [],
          files: [],
        }
      } catch (e) {
        if (
          e.response.data[0] ===
          'These data were already used to apply for this position. You can track the progress of your application using app.lightapply.com system for free.'
        ) {
          this.SET_ERROR_SNACK(e.response.data.join('\n'))
          applySubmitting.value = false
          console.error(e)
        } else {
          this.SET_ERROR_SNACK('Something went wrong. Please try again later or contact contact@lightapply.com')
          applySubmitting.value = false
          console.error(e)
        }
      }
    }
    const applyAnswer = (event, q) => {
      const answerIndex = applyData.value.answers.findIndex((a) => a.question === q.id)
      if (answerIndex === -1) {
        applyData.value.answers.push({
          question: q.id,
          answer: event,
        })
      } else {
        applyData.value.answers[answerIndex].answer = event
      }
    }

    const redirectToCandidatePanel = () => {
      if (process.env.VUE_APP_ENV === 'demo') {
        window.location.replace('https://app.demo.lightapply.com')
      } else if (process.env.VUE_APP_ENV === 'staging') {
        window.location.replace('https://app.staging.lightapply.com')
      } else {
        window.location.replace('https://app.lightapply.com')
      }
    }

    onMounted(async () => {
      try {
        loading.value = true
        const { data } = await axiosIns.get(`company/offer/${router.currentRoute.params.id}/apply/`)
        const { job, company } = data
        jobData.value = job
        jobData.value.additionalConsents = jobData.value.additionalConsents.map((additionalConsent) => ({
          ...additionalConsent,
          isAccepted: false,
          expanded: false,
        }))
        companyData.value = company
        loading.value = false
      } catch (e) {
        if (e.response.data.status === 'This offer is no longer available') {
          applyFormDisabled.value = true
          loading.value = false
        }
        console.error(e)
      }
    })

    // Texts

    const preparingFormHeader = computed(() => {
      if (isEng.value) return "We're preparing application form for you..."
      if (isRu.value) return 'МЫ ГОТОВИМ ДЛЯ ВАС АНКЕТУ...'
      if (isEs.value) return 'Estamos preparando el formulario de solicitud...'

      return 'Przygotowujemy dla Ciebie formularz...'
    })

    const applyFormHeader = computed(() => {
      if (isEng.value) return `Apply for ${jobData.value.title} in ${companyData.value.registrationName}`
      if (isRu.value) return `Подать заявку на ${jobData.value.title} в ${companyData.value.registrationName}`
      if (isEs.value)
        return `Presente su candidatura para el puesto ${jobData.value.title} en ${companyData.value.registrationName}`

      return `Aplikuj na stanowisko ${jobData.value.title} w ${companyData.value.registrationName}`
    })

    const basicInformationHeader = computed(() => {
      if (isEng.value) return 'Basic information'
      if (isRu.value) return 'Основные информации<br />(Просим заполнить ФИО, как в паспорте на английском языке)'
      if (isEs.value) return 'Información básica'

      return 'Podstawowe informacje'
    })

    const firstNameLabel = computed(() => {
      if (isEng.value) return 'First name*'
      if (isRu.value) return 'Имя*'
      if (isEs.value) return 'Nombre*'

      return 'Imię*'
    })

    const lastNameLabel = computed(() => {
      if (isEng.value) return 'Last name*'
      if (isRu.value) return 'Фамилия*'
      if (isEs.value) return 'Apellido*'

      return 'Nazwisko*'
    })

    const emailLabel = computed(() => {
      if (isRu.value) return `электронная почта${jobData.value.emailRequired ? '*' : ''}`
      if (isEs.value) return `Correo electrónico${jobData.value.emailRequired ? '*' : ''}`

      return `Email${jobData.value.emailRequired ? '*' : ''}`
    })

    const phoneNumberLabel = computed(() => {
      if (isEng.value) return `Phone number${jobData.value.phoneRequired ? '*' : ''}`
      if (isRu.value) return `Номер телефона${jobData.value.phoneRequired ? '*' : ''}`
      if (isEs.value) return `Numero de telefono${jobData.value.phoneRequired ? '*' : ''}`

      return `Numer telefonu${jobData.value.phoneRequired ? '*' : ''}`
    })

    const preferredCityLabel = computed(() => {
      if (isEng.value) return `Preferred city to work from${jobData.value.preferredCityRequired ? '*' : ''}`
      if (isRu.value) return `Откуда ты хочешь работать${jobData.value.preferredCityRequired ? '*' : ''}`
      if (isEs.value) return `¿Desde dónde quiere trabajar?${jobData.value.preferredCityRequired ? '*' : ''}`

      return `Skąd chcesz pracować${jobData.value.preferredCityRequired ? '*' : ''}`
    })

    const attachmentLabel = computed(() => {
      if (isEng.value)
        return `Drop file(s) here or <span class='filepond--label-action'>click</span> to select them${
          jobData.value.attachmentsRequired ? '*' : ''
        }`
      if (isRu.value)
        return `Перенесите файл сюда или <span class='filepond--label-action'>щелкните</span>, чтобы выбрать его${
          jobData.value.attachmentsRequired ? '*' : ''
        }`
      if (isEs.value)
        return `Arrastre el archivo aquí o <span class='filepond--label-action'>haga clic</span>, para seleccionarlo${
          jobData.value.attachmentsRequired ? '*' : ''
        }`

      return `Przeciągnij pliki tutaj lub <span class='filepond--label-action'>kliknij</span>, żeby je wybrać${
        jobData.value.attachmentsRequired ? '*' : ''
      }`
    })

    const permitToContactViaEmailText = computed(() => {
      if (isEng.value)
        return `I hereby agree that LightApply Sp. z o.o. may contact me electronically in order to provide information on the recruitment process for position ${jobData.value.title} in company ${companyData.value.registrationName}.`
      if (isRu.value)
        return `Я даю согласие на то, чтобы компания LightApply sp. z o.o. связалась со мной электронным способом для предоставления информации о процессе рекрутациина должность ${jobData.value.title} в компании ${companyData.value.registrationName}.`
      if (isEs.value)
        return `Consiento que LightApply Sp. z o.o se ponga en contacto conmigo por via electrónica con el fin de facilitarme información sobre el proceso de selección para el puesto ${jobData.value.title} en la empresa ${companyData.value.registrationName}.`

      return `Wyrażam zgodę na kontaktowanie się ze mną drogą elektroniczną przez LightApply Sp. z o.o. w celu przekazania informacji dotyczących procesu rekrutacji na stanowisko ${jobData.value.title} w firmie ${companyData.value.registrationName}.`
    })

    const submitButton = computed(() => {
      if (isEng.value) return `Submit`
      if (isRu.value) return `Применить`
      if (isEs.value) return `Enviar`

      return `Aplikuj`
    })

    const submittedHeader = computed(() => {
      if (isEng.value) return `Your application has been submitted`
      if (isRu.value) return `Ваше заявление было подано`
      if (isEs.value) return `Su solicitud ha sido enviada`

      return `Twoja aplikacja została przesłana`
    })

    const submittedInfoLabel = computed(() => {
      if (isEng.value)
        return `After registering with your e-mail you can see all the details about the offer and recruitment process.`
      if (isRu.value)
        return `После входа в систему с помощью электронной почты вы сможете увидеть все детали предложение и процесс найма.`
      if (isEs.value)
        return `Una vez que se haya conectado utilizando su correo electrónico, podrá ver todos los detalles de la oferta y del proceso de contratación.`

      return `Po zalogowaniu przy użyciu e-maila możesz zobaczyć wszystkie szczegóły dotyczące oferty i procesu rekrutacji.`
    })

    const privacyPolicyInfo = computed(() => {
      if (isEng.value)
        return `Apart from the employer mentioned above, LightApply Sp. z o.o., the data processor is responsible for the processing of your data provided in the application form. LightApply sp. z o.o. is co-administrator of your data in order to process your data when you fill in the application form as well as to analyze and encourage you to set up a candidate account. Please be informed that LightApply has no control over processing of your personal data by the recruiting company, the data controller. The data subject has the right to obtain from the controller confirmation as to whether or not personal data concerning him or her are being processed, and, where that is the case to request access, correction, deletion, restriction of processing or transfer data to another controller. You also have the right to object or make a complaint to the President of the Personal Data Protection Office in Poland. Should you have any questions or requests, please don't hesitate to contact us at contact@lightapply.com For more information, see our <a href="https://lightapply.com/pl/polityka-prywatnosci/">Privacy Policy.</a>`
      if (isRu.value)
        return `Ответственность за обработку ваших данных, представленных в процессе данной рекрутации, несет, помимо вышеупомянутого работодателя, LightApply Sp. z o.o., который является оператором персональных данных. LightApply sp. Z o.o. является совместным оператором ваших данных с целью обработки ваших данных при заполнении рекрутационной анкеты, а также с целью поощрения вас к созданию профиля кандидата. Обратите внимание, что LightApply не контролирует обработку ваших личных данных агентством по трудоустройству, которое является обработчиком данных. Вы имеете право получить от администратора подтверждение того, обрабатываются ли ваши персональные данные, и, запросить доступ к ним, исправление, удаление, ограничение обработки или передачу данных другому администратору. Вы также имеете право подать жалобу Управлению по защите личных данных в Польше. По вопросам обращайтесь к нам по адресу contact@lightapply.com. Более подробную информацию можно найти в нашей <a href="https://lightapply.com/pl/polityka-prywatnosci/">Политике конфиденциальности.</a>`
      if (isEs.value)
        return `Además del empleador mencionado, LightApply Sp. z o.o., que es el procesador de datos, es responsable del procesamiento de sus datos proporcionados en el formulario de solicitud. LightApply Sp. z o.o. es corresponsable del tratamiento de sus datos para procesar sus datos al rellenar el formulario de solicitud, así como para analizar y animarle a crear una cuenta de solicitante. Le informamos de que LightApply no tiene ningún control sobre el tratamiento de sus datos personales por parte de la empresa de contratación, que es la responsable del tratamiento. El interesado tiene derecho a obtener confirmación del responsable del tratamiento sobre si se están tratando datos personales que le conciernen y, en caso afirmativo, a solicitar el acceso, la rectificación, la supresión, la limitación del tratamiento o la portabilidad de los datos a otro responsable del tratamiento. También tiene derecho a presentar una objeción o reclamación ante el Presidente de la Oficina de Protección de Datos Personales de Polonia. Si tiene alguna pregunta o solicitud, póngase en contacto con nosotros en contact@lightapply.com. Puede encontrar más información en nuestra <a href="https://lightapply.com/pl/polityka-prywatnosci/">Política de privacidad.</a>`

      return `Za przetwarzanie Twoich danych podanych w formularzu aplikacyjnym odpowiada, oprócz wyżej wymienionego pracodawcy, LightApply Sp. z o.o., która jest podmiotem przetwarzającym dane. LightApply Sp. z o.o. jest współadministratorem Państwa danych w celu przetwarzania Państwa danych podczas wypełniania formularza aplikacyjnego, jak również w celu analizy i zachęcenia Państwa do założenia konta kandydata. Informujemy, że LightApply nie ma kontroli nad przetwarzaniem Twoich danych osobowych przez firmę rekrutacyjną, będącą administratorem danych. Osoba, której dane dotyczą, ma prawo do uzyskania od administratora potwierdzenia, czy przetwarzane są dane osobowe jej dotyczące, a jeśli tak, do żądania dostępu do danych, ich sprostowania, usunięcia, ograniczenia przetwarzania lub przeniesienia danych do innego administratora. Przysługuje Państwu również prawo do wniesienia sprzeciwu lub skargi do Prezesa Urzędu Ochrony Danych Osobowych w Polsce. W przypadku jakichkolwiek pytań lub wniosków, prosimy o kontakt pod adresem contact@lightapply.com. Więcej informacji znajdą Państwo w naszej <a href="https://lightapply.com/pl/polityka-prywatnosci/">Polityce Prywatności.</a>`
    })

    const offerNoLongerAvailableLabel = computed(() => {
      if (isEng.value) return `This offer is no longer available`
      if (isRu.value) return `Это предложение больше не доступно`
      if (isEs.value) return `Esta oferta ya no está disponible`

      return `Ta oferta nie jest już dostępna`
    })

    const candidatePanelLabel = computed(() => {
      if (isEng.value) return `Candidate panel`
      if (isRu.value) return `Панель кандидатов`
      if (isEs.value) return `Panel de candidatos`

      return `Panel kandydata`
    })

    const minimizeLabel = computed(() => {
      if (isEng.value) return `Minimize`
      if (isRu.value) return `Минимизировать`
      if (isEs.value) return `Minimice`

      return `Minimalizuj`
    })

    const expandLabel = computed(() => {
      if (isEng.value) return `Expand`
      if (isRu.value) return `Развернуть`
      if (isEs.value) return `Ampliar`

      return `Rozwiń`
    })

    return {
      applyAnswer,
      redirectToCandidatePanel,
      submitApplication,

      loading,
      valid,

      applyData,
      baseGdprExpanded,
      talentPoolExpanded,

      applied,
      applySubmitting,
      applyFormDisabled,

      jobData,
      companyData,

      isRu,
      isEng,
      isEs,
      canApply,
      rules,

      // Texts
      preparingFormHeader,
      applyFormHeader,
      basicInformationHeader,
      firstNameLabel,
      lastNameLabel,
      emailLabel,
      phoneNumberLabel,
      preferredCityLabel,
      attachmentLabel,
      permitToContactViaEmailText,
      submitButton,
      submittedHeader,
      submittedInfoLabel,
      privacyPolicyInfo,
      offerNoLongerAvailableLabel,
      candidatePanelLabel,
      minimizeLabel,
      expandLabel,
    }
  },
}
</script>

<style scoped></style>
